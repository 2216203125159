import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock, faPlayCircle, faRub} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faVk} from "@fortawesome/free-brands-svg-icons";
import {Row} from "react-bootstrap";
import RoomsMap from "../../../config/rooms.json";
import {format} from "date-fns";
import {ru} from 'date-fns/locale'

class EventPanel extends React.Component<any> {
    getFee() {
        if (this.props.fee === 0) {
            return 'Обычный тариф';
        }
        return (
            <div>
                <span>{this.props.fee}</span>
                <FontAwesomeIcon icon={faRub}/>
            </div>
        );
    }

    getDuration = () => <div>
        <FontAwesomeIcon icon={faClock}/>
        <span className="pl-2">{this.props.duration}</span>
    </div>


    renderTime() {
        const {start} = this.props;
        return start ? (
            <div itemProp="startDate">
                <FontAwesomeIcon icon={faCalendar}/>
                <span className="pl-2">
                    {format(new Date(start), 'dd MMMM в HH:mm', {locale: ru})}
                </span>
            </div>
        ) : 'Не запланировано';
    }


    renderSocial(type: any) {
        if (this.props[type]) {
            let icon;
            switch (type) {
                case "vk":
                    icon = <FontAwesomeIcon icon={faVk}/>;
                    break;
                case "facebook":
                    icon = <FontAwesomeIcon icon={faFacebook}/>;
                    break;
                default:
                    icon = <FontAwesomeIcon icon={faPlayCircle}/>;
                    break;
            }

            return (
                <a className={`event-panel-social-item ${type}`} href={`https://${type}.com/${this.props[type]}`}>
                    {icon}
                </a>
            );
        }
    }

    render() {
        return (
            <div className="container">
                <Row className=" justify-content-between">
                    <div className="col-10">
                              <span className="event-panel-item" itemProp="name">
                            {
                                // @ts-ignore
                                RoomsMap[this.props.place]
                            }
                             </span>
                        <span className="event-panel-item">
                            {this.getFee()}
                        </span>
                        <span className="event-panel-item">
                            {this.renderTime()}
                        </span>
                        <span className="event-panel-item">
                            {this.getDuration()}
                        </span>
                    </div>
                    <div className="col-2 text-right">
                        {this.renderSocial('vk')}
                        {this.renderSocial('facebook')}
                        {
                            <div className="event-panel-social">
                                <span className="event-panel-item community">Сообщество</span>
                            </div>
                        }
                    </div>
                </Row>
                {/* <Row className="justify-content-between">
                    <Col>
                        <div className="event-panel-item" itemProp="name">
                            {
                                // @ts-ignore
                                RoomsMap[this.props.place]
                            }
                        </div>
                        <div className="event-panel-item">
                            {this.getFee()}
                        </div>
                    </Col>
                    <Col md={2} sm={1} className="ms-md-auto ">
                        {this.renderSocial('vk')}
                        {this.renderSocial('facebook')}
                        <div className="event-panel-social">
                            <span className="event-panel-item community">Сообщество</span>

                        </div>
                    </Col>
                </Row>*/}

            </div>
        );
    }
}

export default EventPanel;
