import React, {useEffect, useState} from "react";
import Footer from "../../layout/Footer";
import {useParams, useSearchParams} from "react-router-dom";
import {API_EVENT_BY_NAME, EVENT_IMG_URL} from "../../config/urls";
import {EventModel} from "../../models/event";
import EventPanel from "./View/EventPanel";
import EventTitle from "./View/EventTitle";
import Navigation from "../../layout/Navigation";

const FeedView = () => {
    let {name} = useParams();

    const [searchParams] = useSearchParams();

    // get start date from url
    const startString = searchParams.get("start")
    const startDateParam = startString ? new Date(startString) : null;

    const [data, setData] = useState<EventModel>();
    //const [data, setData] = useState<Product>();
    console.log(name, searchParams.get("start"))
    console.log(typeof searchParams.get("start"))
    useEffect(() => {
        fetch(API_EVENT_BY_NAME + "/?url=" + name)
            .then(response => response.json())
            .then(data => {
                    setData(data.data)
                    //console.log(data.data)
                }
            )
    }, [name])


    return (
        <div className="h-100">
            <header className={"event-cover"}
                    style={{'backgroundImage': `url("${EVENT_IMG_URL}` + data?.image_name + `")`}}>
                <Navigation/>
                <div className="event-title-container">
                    <EventTitle
                        name={data?.name}
                        eventType={data?.eventType}
                        start={startDateParam ?? data?.start}
                    />
                </div>
                <div className="event-panel-container">
                    <EventPanel {...data} start={startDateParam ?? data?.start}/>
                </div>

                <div className="header-quote text-center align-items-center">
                    {/* {this.renderQuoteAuthor()}*/}
                </div>
            </header>

            <div className="container">
                <div className="card p-3">
                    <div className="event" itemScope itemType="http://schema.org/Event">
                        <div className="event-content-container">
                            <div className="event-content">
                                <div itemProp="description"
                                     dangerouslySetInnerHTML={{__html: data?.description ?? ''}}/>
                                {/*      <Gratitude links={['zovem.ru', 'tandp.ru', '2do2go.ru', '2anticafe.com']}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


export default FeedView;